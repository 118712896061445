.icon-button {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 48px; 
    height: 48px;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    outline: none; 
    transition: background 0.3s; 
}

.icon-button svg {
    width: 22px; 
    height: 22px; 
}

.icon-button.active,
.icon-button:hover {
    background: rgba(255, 255, 255, 0.5);
}

.icon-button.small {
    background: none;
    width: 32px;
    height: 32px;
  }
  
  .icon-button.small svg {
    width: 16px;
    height: 16px;
  }
@media (max-width: 768px) {
    .icon-button {
        width: 40px;
        height: 40px;
    }

    .icon-button svg {
        width: 20px;
        height: 20px;
    }
    .icon-button:hover {
        background: rgba(255, 255, 255, 0.2);
    }
    .icon-button.active {
    background: rgba(255, 255, 255, 0.5);
}
}


