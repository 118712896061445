.volume-control__container{
    width: 100%;
    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
    }

    input[type="range"]:focus {
        outline: none;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1rem;
        height: 1rem;
        background-color: #D9D9D9;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -5px;
    }

    input[type="range"]::-webkit-slider-runnable-track{
        background: #fff;
        height: 5px;
        border-radius: 5px;
    }
}