/* Chat.css */
.chat {
    position: absolute;
    top: 100%; /* This will position it right under the buttons */
    right: 64px; /* 64px from the right side */
    width: 324px;
    height: 480px;
    background-color: rgba(128, 128, 128, 0.95); /* Gray with 95% opacity */
    display: flex;
    flex-direction: column; /* Stack chat box above chat input */
    justify-content: flex-end; /* Align items to the bottom */
    align-items: flex-start; /* Align items to the left */
    z-index: 1001;
    border-radius: 8px; /* Add some rounded edges */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Some shadow for elevation */
  }
  
  .chat-box {
    display: flex;
    flex-direction: column; /* Stack messages from top to bottom */
    overflow-y: auto;
    padding: 10px;
    background-color: #f0f0f0;
    margin-bottom: 10px; /* Add space between chat box and chat input */
    background-color: rgba(128, 128, 128, 0);
  }
  
  .message {
    display: flex; /* Make the message content align horizontally */
    align-items: center; /* Center the items vertically */
    margin-bottom: 10px;
    color: white;
  }
  .profile-circle-container {
    margin-right: 10px; /* Add space between the circle and message content */
  }
  
  .message-content-container {
    display: flex;
    flex-direction: column;
  }
  .message-header {
    font-weight: bold;
    color: white;
    margin-bottom: 0%;
    margin-top: 0%;
  }
  .chat-input-container {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start; /* Align items to the left */
    align-items: flex-end; /* Align input to the bottom */
    padding: 10px 10px 10px; /* Add padding to top and bottom, not left and right */
    background-color: rgba(30, 30, 30, 0.8);
    width: 100%;
    border-bottom-left-radius: 8px; /* Add rounded bottom left corner */
    border-bottom-right-radius: 8px; /* Add rounded bottom right corner */
  }
  
  .chat-input {
    display: flex;
    justify-content: space-between; /* Create space between input and button */
    align-items: center; /* Vertically align input and button */
    padding: 0px; /* Add padding to all sides */
    background-color: rgba(128, 128, 128, 0.95);
    width: 100%;
    border-radius: 50px; /* Create oval shape */
  }
  
  .chat-input input {
    flex: 1; /* Expand input to take available space */
    height: 100%; /* Make input fill the height of the oval */
    margin-right: 10px; /* Add space between input and button */
    margin-left: 10px;
    padding: 5px 10px; /* Add padding inside the oval */
    border: none;
    background-color: transparent; /* Make input background transparent */
    color: white;
    border-top-left-radius: 50px; /* Adjust top left corner to match oval */
    border-bottom-left-radius: 50px; /* Adjust bottom left corner to match oval */
  }

 