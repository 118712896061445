.toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #5F5F5F;
    border-radius: 8px;
    padding: 1px;
    width: 100%;
    height: 3rem;
}
  
.toggle-option {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &--left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &--right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .toggle-icon{
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
}

.toggle-option.active {
    background-color: #fff;
    color: #000
}

.toggle-option:not(.active):hover {
    background-color: #5F5F5F;
    color: #fff;
}
    