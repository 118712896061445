.map {
    position: fixed; /* Change from absolute to fixed */
    top: 0;
    left: 0;
    width: 100vw;   /* Ensures it spans the entire viewport width */
    height: 100vh;  /* Ensures it spans the entire viewport height */
    background-color: rgba(128, 128, 128, 0.95); /* Gray with 70% opacity */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 1001; /* To ensure the overlay is above other content */
    background-image: url('./map-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    column-gap: 5%;
}
.theater-container{
    margin-bottom: 15%;
}
.map-exit-button {
    position: absolute; /* Absolute positioning within the overlay */
    top: 10px; /* 10px from the top of the overlay */
    right: 10px; /* 10px from the right edge of the overlay */
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.map-exit-button:hover {
    background-color: rgba(0, 0, 0, 0.2); /* Slight darkening on hover */
}

.chat {
    position: absolute;
    top: 100%; /* This will position it right under the buttons */
    right: 64px; /* 64px from the right side */
    width: 324px;
    height: 480px;
    background-color: rgba(128, 128, 128, 0.95); /* Gray with 95% opacity */
    display: flex;
    flex-direction: column-reverse; /* Stack chat input above chat box */
    justify-content: flex-end; /* Align chat input to the bottom */
    align-items: flex-start; /* Align items to the left */
    z-index: 1001;
    border-radius: 8px; /* Add some rounded edges */
    box-shadow: 0 4px 8px rgba(225, 192, 192, 0.2); /* Some shadow for elevation */
  }

@media (max-width: 768px) {
    .chat {
        right: 24px; /* Adjusted to 24px from the right side */
    }

    .settings {
        right: 24px; /* Adjusted to 24px from the right side */
    }
}
