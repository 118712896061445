.App {
  text-align: center;

  &-logo {
    height: 40vmin;
    pointer-events: none;

    @media (prefers-reduced-motion: no-preference) {
      animation: App-logo-spin infinite 20s linear;
    }
  }
}

@media (min-width: 768px) {
  .joystick-container {
    display: none;
  }
}

.joystick-container {
  position: fixed;
  left: 56px;
  bottom: 156px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  position: fixed;
  width: 100%;
  height: 100%;
}

.icon-container {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #0000008c;
  border-radius: 120px;
  padding-left: 8px;
  padding-right: 8px;
}

html, body{
  width: 100vw;
  height: 100vh;
}
