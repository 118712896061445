.settings {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1.5rem;
    position: absolute;
    top: 100%; /* This will position it right under the buttons */
    height: fit-content;
    background: #212121; /* Gray with 95% opacity */
    opacity: 90%;
    z-index: 1001;
    border-radius: 8px; /* Add some rounded edges */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Some shadow for elevation */

    &.desktop{
        right: 64px; /* 64px from the right side */
        width: 294px;
    }

    &.mobile{
        right: 24px; /* 24px from the right side */
        width: 100%;
    }

    .name-input__container {
        display: flex;
        align-items: center;

        .input-box{
            width: 100%;
            margin-right: 12px;
        }

        .input-box:focus{
            outline: none;
        }
    
        .input-box,
        .button {
            box-sizing: border-box;
            height: 40px;
            border: 1px solid white;
            border-radius: 8px;
            background-color: #1A1A1A;
            color: white;
            padding: 5px 10px;
        }
    
        .button {
            cursor: pointer;
            background-color: #1A1A1A;
            &.saved {
                background-color: #7B7B7B;
            }
        }
    }

    .setting-header{
        margin-bottom: 12px;
    }
}
