.global-nav {
    position:relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 64px;
    z-index: 1000;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); 
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .logo {
    font-weight: bold;
  }
  
  .icons button {
    margin: 0px 12px;
  }

  .main-logo-image {
    width: 65px;
    height: 84px;
  }
  
  .icons {
    display: flex; /* Added to layout the buttons horizontally */
    align-items: center; /* Align buttons vertically in the center */
}

@media (max-width: 768px) {
    .icons button {
        margin: 0px 4px;
    }
    .global-nav {
        padding: 24px 24px;
    }
}