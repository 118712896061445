.element-container {
    display: flex;
    width: 25%;
    flex-wrap: wrap;
    padding: 5%;
    flex-direction: column;
    align-items: flex-start;
    gap: 4%;
    border-radius: 24px;
    background: rgba(33, 33, 33, 0.90);
    color:white;
}
#element-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#explore {
    width: 100%;
    background-color: white;
    color:black;
    border:none;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 5px;
    font-weight: bold;
    cursor:pointer;
}

#caret {
    position: absolute;
    bottom:255px;
    right: 710px;
}

#location-dot, #here-div {
    margin-left: auto;
    margin-right:auto;
}

